@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;500;700;800&display=swap');
@import './assets/calendar.css';
@import './assets/index-images.css';
@import url('../node_modules/swiper/swiper.css');
@import url('../node_modules/swiper/swiper-bundle.css');
@import url('../node_modules/yet-another-react-lightbox/dist/styles.css');


@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply font-base;
    @apply text-lg;
    @apply text-black;
  }

  h1, .h1 {
    font-size: 30px;
    line-height: 48px;
    font-weight: normal;
  }

  h2, .h2 {
    font-size: 24px;
    line-height: 32px;
    font-weight: normal;
  }

  h3, .h3 {
    font-size: 24px;
    line-height: 30px;
    font-weight: normal;
  }

  h4, .h4 {
    font-size: 20px;
    line-height: 24px;
    font-weight: normal;
  }

  h5, .h5 {
    font-size: 16px;
    line-height: 24px;
    font-weight: bold;
  }

  h6, .h6 {
    font-size: 14px;
    line-height: 18px;
    font-weight: bold;
  }

  @screen lg {
    h1, .h1 {
      font-size: 42px;
      line-height: 60px;
    }

    h2, .h2 {
      font-size: 36px;
      line-height: 48px;
    }

    h3, .h3 {
      font-size: 30px;
      line-height: 36px;
    }

    h4, .h4 {
      font-size: 24px;
      line-height: 32px;
    }

    h5, .h5 {
      font-size: 18px;
      line-height: 24px;
    }

    h6, .h6 {
      font-size: 16px;
      line-height: 18px;
    }
  }

  .writing-vertical {
    writing-mode: vertical-rl;
  }

  .shadow-right {
    box-shadow: 0 0 10px rgba(0, 0, 0, .15);
    clip-path: inset(0px -10px 0px 0px);
  }

  .shadow-left {
    box-shadow: 0 0 10px rgba(0, 0, 0, .15);
    clip-path: inset(0px 0px 0px -10px);
  }

}
