.react-datepicker-popper {
  @apply bg-white ring-ternary-light border border-gray-light rounded p-4 z-10;
  @apply xl:focus-within:ring-3 xl:focus-within:border-ternary;
}

.react-datepicker__day {
  @apply text-md w-8 h-8 flex items-center justify-center focus:bg-gray-light focus:outline-none rounded cursor-pointer;
  @apply hover:bg-gray-light focus:bg-gray-light;
}

.react-datepicker__day--today {
  @apply text-secondary bg-ternary-light;
}

.react-datepicker__day--highlighted {
  @apply bg-success-light;
}

.react-datepicker__day--selected {
  @apply bg-primary !important;
  @apply text-white;
}

.react-datepicker__day--disabled {
  @apply bg-secondary-light text-gray cursor-not-allowed;
  @apply hover:text-gray;
  @apply hover:bg-secondary-light;
}

.react-datepicker__week {
  @apply flex;
}

.react-datepicker__day-name {
  @apply text-md w-8 h-8 flex items-center justify-center text-gray-dark;
}

.react-datepicker__day-names {
  @apply flex;
}
