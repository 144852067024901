/*
  Based on TailwindCSS recommendations,
  consider using classes instead of the `@apply` directive
  @see https://tailwindcss.com/docs/reusing-styles#avoiding-premature-abstraction
*/
/* Spoločná trieda pre tieňový efekt */
.box-shadow-effect {
  @apply shadow-[14px_14px_6px_0_rgba(0,0,0,0),9px_9px_5px_0_rgba(0,0,0,0.01),5px_5px_4px_0_rgba(0,0,0,0.03),2px_2px_3px_0_rgba(0,0,0,0.04),1px_1px_2px_0_rgba(0,0,0,0.05)];
}
.index-images .image-wrapper {
  @apply absolute transition-transform duration-[0.3s] ease-[ease-in-out] scale-[0.85] right-0 top-0 first:z-[1] first:scale-100 first:left-0 first:top-0 hover:z-[2] hover:scale-100;
}
.index-images:hover .image-wrapper:not(:hover) {
  @apply scale-[0.85];
}
.index-images:not(:hover) .image-wrapper:first-child {
  @apply scale-100;
}
.index-images:not(:hover) .image-wrapper:not(:first-child) {
  @apply scale-[0.85];
}
